<template>
  <div id="admin">
    <v-container style="text-align: center">
    <h2>Admin Dashboard</h2>

      <div id="userTableArea" style="margin-top: 20px">
        <v-card>
          <v-card-title>
            <div style="text-align: left">
            <h4>Users</h4>
              <!-- <div style="font-size: 0.85em"> {{uploadedFileSummary}} </div> -->
              <div style="margin-top: 5px; min-height: 36px">
                <v-btn v-show="allUserProfiles.length > 0"
                @click="deleteSelectedUsers">
                  Delete {{selectedUsers.length}} Users
                </v-btn>
              </div>
            </div>
              <v-spacer/>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details />
          </v-card-title>
          <v-data-table
            v-model="selectedUsers"
            item-key="uid"
            show-select
            :headers="userHeaders"
            :items="userItems"
            :items-per-page="15"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:item.clearstorage="{item}">
              <v-btn style="font-size: 0.8em; height: 60%" 
              @click="removeUserFiles(item.uid)">
              Remove all files</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <div class="existingAudioFilesDiv" style="margin-top: 20px">
        <v-card>
          <v-card-title>
            <div style="text-align: left">
            <h4>Uploaded Audio Files</h4>
              <div style="font-size: 0.85em; white-space: pre-wrap">{{uploadedFileSummary}} </div>
              <div style="margin-top: 5px; min-height: 36px">
                <v-btn v-show="selectedAudioFiles.length > 0"
                @click="deleteSelectedFiles">
                  Delete {{selectedAudioFiles.length}}
                </v-btn>
              </div>
            </div>
              <v-spacer/>
              <v-text-field
                v-model="searchFiles"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details />
          </v-card-title>
          <v-data-table
            v-model="selectedAudioFiles"
            item-key="name"
            show-select
            :headers="audioFileHeaders"
            :items="audioFileItems"
            :items-per-page="15"
            :search="searchFiles"
            class="elevation-1"
          >
            <template v-slot:item.name="{item}">
              <a :href=item.url target="_blank">{{item.name}}</a>
            </template>
          </v-data-table>
        </v-card>
      </div>

    </v-container>
  </div>
</template>

<script>
import fb from '@/firebase.js'

export default {
  name: "Administraataa",
  data() {
    return {
      allUserProfiles: [],
      allAudioFilesUserMap: {},
      userAudioSizeTotals: {},
      selectedUsers: [],
      search: '',
      searchFiles: '',
      allAudioFilesList: [],
      selectedAudioFiles: [],
      idEmailMap: {}
    }
  },
  computed: {
    userHeaders() {
      return [
        {text: "Email", align: 'start', sortable: true, value: 'email'},
        {text: "UID", align: 'start', sortable: true, value: 'uid'},
        {text: "Username", align: 'start', sortable: true, value: 'displayName'},
        {text: "Storage", align: 'start', sortable: true, value: 'storage'},
        {text: "Clear storage", align: 'center', sortable: true, value: 'clearstorage'},
      ]
    },
    userItems() {
      let ret = [];
      let items = this.allUserProfiles;
      for(var i = 0; i < items.length; ++i) {
        const item = items[i];
        let obj = {
          email: item.email,
          uid: item.uid,
          displayName: item.displayName
        }
        if(this.allAudioFilesUserMap[item.uid]) {
          obj.storage = (this.userAudioSizeTotals[item.uid]/1000000).toFixed(2) + " Mb";
          const numFiles = this.allAudioFilesUserMap[item.uid] ?
          this.allAudioFilesUserMap[item.uid].length : 0;
          obj.storage += " ("  + numFiles + " files)"
        }
        else {
          obj.storage = "0 Mb (0 files)"
        }

        ret.push(obj);
      }
      return ret;
    },
    uploadedFileSummary() {
      let items = this.allAudioFilesList;
      let str = items.length + " files, total size:\n";

      let sizeSum = 0;
      items.forEach(item => {
        sizeSum += item.size;
      })
      sizeSum /= 1000000;
      str += sizeSum.toFixed(2) + "MB";
      str += " (250MB max)";
      return str;
    },
    audioFileHeaders() {
      return [
        {text: "Filename", align: 'start', sortable: true, value: 'name'},
        {text: "Duration", align: 'start', sortable: true, value: 'duration'},
        {text: "Sample Rate", align: 'start', sortable: true, value: 'samplerate'},
        {text: "Date Added", align: 'start', sortable: true, value: 'dateadded'},
        {text: "Size (MB)", align: 'start', sortable: true, value: 'sizemb'},
        {text: "User", align: 'start', sortable: true, value: 'userEmail'}
      ]
    },
    audioFileItems() {
      let ret = [];
      let items = this.allAudioFilesList;
      for(var i = 0; i < items.length; ++i) {
        const item = items[i];
        const str = this.shortDateStr(item.dateAdded);
        const obj = {
          name: item.name,
          duration: this.sec2Min(item.metadata.duration.toFixed(2)),
          samplerate: item.metadata.sampleRate,
          dateadded: str,
          sizemb: (item.size/1000000).toFixed(2),
          id: item.id,
          url: item.url,
          userEmail: this.idEmailMap[item.userId],
          userId: item.userId
        }
        ret.push(obj);
      }
      return ret;
    },
  },
  methods: {
    removeUserFiles(uid) {
      console.log("Will delete user files for: " + uid)
      let files = _.cloneDeep(this.allAudioFilesUserMap[uid]);
      this.deleteFilesInList(files);
    },
    deleteSelectedUsers() {
      let users = _.cloneDeep(this.selectedUsers);
      users.forEach(user => {
        fb.usersCollection.doc(user.uid).delete().then(() => {
          
        });
      })
    },
    deleteSelectedFiles() {
      this.deleteFilesInList(this.selectedAudioFiles);
    },
    deleteFilesInList(list) {
      var storageRef = fb.storage.ref();
      const filesToDelete = _.cloneDeep(list);
      this.selectedAudioFiles = []
      for(var i = 0; i < filesToDelete.length; ++i) {
        const item = filesToDelete[i];
        const storagePath = "user_files/" + item.userId 
        + "/audio/" + item.name;
        // Create a reference to the file 
        var ref = storageRef.child(storagePath);
        ref.delete().then(() => {
          // remove it from the database
          fb.audioFilesCollection.doc(item.id).delete().then(() => {
          }).catch(err => {
            console.log("Error removing the audio file document: " + err)
          })
        }).catch(err => {
          console.log("Error deleting file: " + storagePath + ": " + err)
        });
      }
    },
    shortDateStr(d) {
      const date = new Date(d);
      const options = {short: true}
      return date.toLocaleDateString(options) + "\n" + date.toLocaleTimeString(options)
    },
    sec2Min(sec) {
      let numMin = sec / 60;
      const numMinFloor = Math.floor(numMin);
      const remainingSec = sec - numMinFloor*60;
      let milliSec = remainingSec - Math.floor(remainingSec);
      milliSec = Math.floor(milliSec * 1000)
      let minString = "" + numMinFloor;
      minString = minString.padStart(2, '0');
      let secString = "" + Math.floor(remainingSec);
      secString = secString.padStart(2, '0');
      let msecString = milliSec + "";
      msecString = msecString.padStart(3, '0');
      const ret = minString + ":" + secString + ":" + msecString
      return ret;
    },
    updateUserMap() {

    }
  },
  mounted() {
      fb.usersCollection.onSnapshot((docs) => {
        this.allUserProfiles = [];
        docs.forEach(doc => {
          const data = doc.data();
          this.idEmailMap[data.uid] = data.email;
          this.allUserProfiles.push(doc.data());
          
        });
      });

      fb.audioFilesCollection.onSnapshot((docs) => {
        this.allAudioFilesUserMap = {};
        this.userAudioSizeTotals = {};
        this.allAudioFilesList = [];
        docs.forEach(doc => {
          const data = doc.data();
          data.id = doc.id;
          this.allAudioFilesList.push(data);
          if(!this.allAudioFilesUserMap[data.userId]) {
            this.allAudioFilesUserMap[data.userId] = []
          }
          this.allAudioFilesUserMap[data.userId].push(data);
          if(!this.userAudioSizeTotals[data.userId]) {
            this.userAudioSizeTotals[data.userId] = 0;
          }
          this.userAudioSizeTotals[data.userId] += data.size;
        })
      })
  }
}
</script>

<style scoped>
.usertable {
  white-space: pre-wrap;
  text-align: left;
}

</style>